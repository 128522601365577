import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "4b19b3e4-88ce-4199-9bb6-fa537dac159e"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages4b19b3e488ce41999bb6Fa537dac159e(props: any) {
  return (
    <SecondaryPage70e782399a2143f5978f6f108031c2d8 parentTag="SignupPages4b19b3e488ce41999bb6Fa537dac159e" header={<SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header8caa48d24ba040b28fd161c1a2b50cc1" title="Join Us" />} {...props} />
  );
}

// id: "2ac2a1b0-bc8c-4922-8739-204098c8b1d6"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "8088edd1-e490-4e36-b1b1-5ffd140601be"
export function WelcomeBack2ac2a1b0Bc8c49228739204098c8b1d6(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp className="WelcomeBack2ac2a1b0Bc8c49228739204098c8b1d6" parentTag={buildClassName("WelcomeBack2ac2a1b0Bc8c49228739204098c8b1d6", parentTag)} {...props} />
  );
}

// id: "cf45f16b-a104-4961-b653-cfd13b804f15"
// title: "Footer"
// type: :reference
// key: "footer"
// parent_id: "a4d3b278-87e2-4828-992d-024ae228f018"
export function FooterCf45f16bA1044961B653Cfd13b804f15(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterCf45f16bA1044961B653Cfd13b804f15" {...props} />
  );
}

// id: "70e78239-9a21-43f5-978f-6f108031c2d8"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage70e782399a2143f5978f6f108031c2d8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage70e782399a2143f5978f6f108031c2d8 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header1f13819cBe5a4682A520B0da972f8cec" title="Secondary Page" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterCebebb34De6748899ced56c67d8c8c0f" {...props} />}
    </div>
  );
}

// id: "c93640ad-9569-4a80-be7e-1be2163d3dea"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "38ba37dd-c2b7-4990-859a-127ad9b145a4"
export const NavMenuSlugC93640ad95694a80Be7e1be2163d3dea = "marketing-primary-nav";

// id: "83f172d7-d278-45a5-afb0-f884dff24735"
// title: ""
// type: :text
// key: "title"
// parent_id: "3732986a-257d-4776-80f8-7b18ee604e30"
export const Title83f172d7D27845a5Afb0F884dff24735 = "Default Title";

// id: "cdc86cd2-f8a8-4a60-8f93-2d402fdc8322"
// title: "Footer Clone xRXtkVcbB7vf9dR"
// type: :reference
// key: "footer"
// parent_id: "0d142b59-382d-480d-8e3a-c17e76089664"
export function FooterCdc86cd2F8a84a608f932d402fdc8322(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterCdc86cd2F8a84a608f932d402fdc8322" {...props} />
  );
}

// id: "f9be20f1-0db9-4c3f-bac5-060369730679"
// title: ""
// type: :gf_preset
// key: "hero"
// parent_id: "7dbfd219-0a83-4f4b-b336-f39ec03b576a"
export function HeroF9be20f10db94c3fBac5060369730679(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Hero className="HeroF9be20f10db94c3fBac5060369730679" parentTag={buildClassName("HeroF9be20f10db94c3fBac5060369730679", parentTag)} postSlug="home-hero" {...props} />
  );
}

// id: "cd857a08-b72a-435c-8826-0d832644e316"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "7dbfd219-0a83-4f4b-b336-f39ec03b576a"
export const BackgroundImageCd857a08B72a435c88260d832644e316 = "https://mp1md-pub.s3.amazonaws.com/spp/images/page-background-photo.jpg";

// id: "64d01229-276b-4c04-94bf-fc84b5cc2e37"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "1ec8c0ee-d264-4b10-8aeb-6657e822d74e"
export const NavMenuSlug64d01229276b4c0494bfFc84b5cc2e37 = "members-primary-nav";

// id: "0c20e20a-da33-4872-826a-7e0981e75433"
// title: ""
// type: :text
// key: "title"
// parent_id: "99c10a86-9cad-496e-bb0d-2569310c928a"
export const Title0c20e20aDa334872826a7e0981e75433 = "Polytopia 2024";

// id: "79aafcb8-63a0-4409-aba3-326e382349ed"
// title: ""
// type: :text
// key: "title"
// parent_id: "f2fc6797-6d9c-4743-b009-16c37e3f007c"
export const Title79aafcb863a04409Aba3326e382349ed = "Join Us";

// id: "056c6c97-7a58-4ac0-a415-fad1445a25ec"
// title: ""
// type: :text
// key: "title"
// parent_id: "10dd4465-fc2b-488c-9ec6-6e1472cfb5ff"
export const Title056c6c977a584ac0A415Fad1445a25ec = "About Us";

// id: "c9877057-bcf8-4b23-8f03-2df90c0a7abd"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "bfd6523c-0ead-43f5-b864-76d5af4dc20c"
export const NavMenuSlugC9877057Bcf84b238f032df90c0a7abd = "marketing-primary-nav";

// id: "db08870a-1bc0-4537-abe0-2c8272a5529a"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyDb08870a1bc04537Abe02c8272a5529a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyDb08870a1bc04537Abe02c8272a5529a page-body`}>
      {props["marketingCards"] || <MarketingCardsAf0bc211026a4cddB4a10976ad128dc6 parentTag="MarketingCardsB6f5dac972a54f948bd3A4b40f5fbe8b" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "bb943482-580c-43a8-b2fd-d7d45fbf6fc7"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPageBb943482580c43a8B2fdD7d45fbf6fc7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPageBb943482580c43a8B2fdD7d45fbf6fc7 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header10dd4465Fc2b488c9ec66e1472cfb5ff" title="About Us" {...props} />}
      {props["body"] || <MarketingBodyDb08870a1bc04537Abe02c8272a5529a parentTag="Body55f86eba15744564B341Ec653e9f847c" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="Footer862860bfB0964a7dB8549175b26b8c37" {...props} />}
    </div>
  );
}

// id: "d295e04d-5406-4567-a830-252bcef5d7d9"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteD295e04d54064567A830252bcef5d7d9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteD295e04d54064567A830252bcef5d7d9`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "ed89e701-bdfe-45f0-959a-efc1d7ec1813"
// title: ""
// type: :gf_preset
// key: "body"
// parent_id: "edbfdf63-e623-4dc6-90dd-62f8297be560"
export function BodyEd89e701Bdfe45f0959aEfc1d7ec1813(props: any) {
  const PageContent = AppStore.presets["PageContent"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageContent className="BodyEd89e701Bdfe45f0959aEfc1d7ec1813" parentTag={buildClassName("BodyEd89e701Bdfe45f0959aEfc1d7ec1813", parentTag)} {...props} />
  );
}

// id: "9c5e5765-5a9c-4563-82fd-0f0ba437c558"
// title: ""
// type: :reference
// key: "header"
// parent_id: "edbfdf63-e623-4dc6-90dd-62f8297be560"
export function Header9c5e57655a9c456382fd0f0ba437c558(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header9c5e57655a9c456382fd0f0ba437c558" title="Polytopia 2024 Workshops" {...props} />
  );
}

// id: "9f713410-576d-490c-b738-1791fec76e8f"
// title: ""
// type: :text
// key: "class"
// parent_id: "17de7fb2-5703-41d6-9ca5-f61c9613aa75"
export const Class9f713410576d490cB7381791fec76e8f = "linked-logo";

// id: "2a3c03ac-1182-4fae-9cb9-d5f68328aae0"
// title: ""
// type: :text
// key: "title"
// parent_id: "9c5e5765-5a9c-4563-82fd-0f0ba437c558"
export const Title2a3c03ac11824fae9cb9D5f68328aae0 = "Polytopia 2024 Workshops";

// id: "97bfcb6d-4756-47aa-960f-942831299efa"
// title: ""
// type: :text
// key: "title"
// parent_id: "5fb71b8c-5182-41c4-8038-63d6e1dd4492"
export const Title97bfcb6d475647aa960f942831299efa = "Public Events";

// id: "9cffba07-7b72-47e1-b888-3703364f9676"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header9cffba077b7247e1B8883703364f9676(props: any) {
  return (
    <MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1 parentTag="Header9cffba077b7247e1B8883703364f9676" {...props} />
  );
}

// id: "015fd256-c465-42d1-933c-9cfa4760a9d1"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1(props: any) {
  return (
    <ShortHeader7403000c468f4c77A1322b994e91c64b parentTag="MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1" header-top={<DefaultHeaderTop69a6404f9361441d89c8Dc07071d5e63 parentTag="HeaderTop1ec8c0eeD2644b108aeb6657e822d74e" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "f8f10c65-3dfb-4c1e-8ad2-2056ebdccbbc"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7dce09fb-f4e6-4faa-81c9-a0f57e6de6f4"
export function HeaderF8f10c653dfb4c1e8ad22056ebdccbbc(props: any) {
  return (
    <MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1 parentTag="HeaderF8f10c653dfb4c1e8ad22056ebdccbbc" {...props} />
  );
}

// id: "38ba37dd-c2b7-4990-859a-127ad9b145a4"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader38ba37ddC2b74990859a127ad9b145a4(props: any) {
  return (
    <ShortHeader7403000c468f4c77A1322b994e91c64b parentTag="DefaultHeader38ba37ddC2b74990859a127ad9b145a4" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "a8e9b68a-7af0-4c82-a184-0b0ee7948196"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultA8e9b68a7af04c82A1840b0ee7948196(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultA8e9b68a7af04c82A1840b0ee7948196 page`}>
      {props["header"] || <MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1 parentTag="Header20aa384b6f234e679420Bf1aae63ed3a" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterBefc69ae8d204d3bAf00A1dbde7e9353" {...props} />}
    </div>
  );
}

// id: "a4d3b278-87e2-4828-992d-024ae228f018"
// title: "Polytopia 2024"
// type: :html
// key: "polytopia-2024"
// parent_id: nil
export function Polytopia2024A4d3b27887e24828992d024ae228f018(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Polytopia2024A4d3b27887e24828992d024ae228f018 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header99c10a869cad496eBb0d2569310c928a" title="Polytopia 2024" {...props} />}
      <main>
        {props["body"] || <MarketingCardsAf0bc211026a4cddB4a10976ad128dc6 parentTag="Body121720d22d35485b807aDe46f8252272" postSlug="polytopia-2024" {...props} />}
      </main>
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterCf45f16bA1044961B653Cfd13b804f15" {...props} />}
    </div>
  );
}

// id: "0d142b59-382d-480d-8e3a-c17e76089664"
// title: "Polytopia 2025"
// type: :html
// key: "polytopia-2025"
// parent_id: nil
export function Polytopia20250d142b59382d480d8e3aC17e76089664(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Polytopia20250d142b59382d480d8e3aC17e76089664 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="HeaderDada08793bc14cd1B01c7dfd0a3ef279" title="Polytopia 2025" {...props} />}
      <main>
        {props["body"] || <MarketingCardsAf0bc211026a4cddB4a10976ad128dc6 parentTag="Body8c5caf07A785488bAa0785849d94dc3d" postSlug="polytopia-2025" {...props} />}
      </main>
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterCdc86cd2F8a84a608f932d402fdc8322" {...props} />}
    </div>
  );
}

// id: "8a54bf4f-ed67-4b8d-a9e2-526dfb479e4f"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "71b91254-e22f-4e37-bd01-ac1376765f0f"
export function Copyright8a54bf4fEd674b8dA9e2526dfb479e4f(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright className="Copyright8a54bf4fEd674b8dA9e2526dfb479e4f" parentTag={buildClassName("Copyright8a54bf4fEd674b8dA9e2526dfb479e4f", parentTag)} {...props} />
  );
}

// id: "10dd4465-fc2b-488c-9ec6-6e1472cfb5ff"
// title: ""
// type: :reference
// key: "header"
// parent_id: "bb943482-580c-43a8-b2fd-d7d45fbf6fc7"
export function Header10dd4465Fc2b488c9ec66e1472cfb5ff(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header10dd4465Fc2b488c9ec66e1472cfb5ff" title="About Us" {...props} />
  );
}

// id: "0fae61c9-9c96-4b7f-bb19-d9a14ebe41f2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9100b6d7-8322-4894-9a12-936c9b39d6f6"
export function Header0fae61c99c964b7fBb19D9a14ebe41f2(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header0fae61c99c964b7fBb19D9a14ebe41f2" title="Leveling" {...props} />
  );
}

// id: "7ffde64f-09cb-402e-951f-e89a4b6abac5"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "8088edd1-e490-4e36-b1b1-5ffd140601be"
export function Footer7ffde64f09cb402e951fE89a4b6abac5(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="Footer7ffde64f09cb402e951fE89a4b6abac5" {...props} />
  );
}

// id: "5fb71b8c-5182-41c4-8038-63d6e1dd4492"
// title: ""
// type: :reference
// key: "header"
// parent_id: "d54dc73e-1286-411d-bd7f-17d177c4c87c"
export function Header5fb71b8c518241c4803863d6e1dd4492(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header5fb71b8c518241c4803863d6e1dd4492" title="Public Events" {...props} />
  );
}

// id: "60dd333a-cbb1-404f-a5ff-c00f06407906"
// title: ""
// type: :text
// key: "title"
// parent_id: "1f13819c-be5a-4682-a520-b0da972f8cec"
export const Title60dd333aCbb1404fA5ffC00f06407906 = "Secondary Page";

// id: "9100b6d7-8322-4894-9a12-936c9b39d6f6"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage9100b6d7832248949a12936c9b39d6f6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage9100b6d7832248949a12936c9b39d6f6 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header0fae61c99c964b7fBb19D9a14ebe41f2" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterD87244536fe14a5a9c92A8f69248bad5" {...props} />}
    </div>
  );
}

// id: "72450035-244a-4d62-9c9f-626d6446fded"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages72450035244a4d629c9f626d6446fded(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages72450035244a4d629c9f626d6446fded gf-page-layout`}>
      {props["header"] || <MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1 parentTag="HeaderC2a2c5e31e04468cA208Ea9ea5b2abc2" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation className="SecondaryNavDa68a611Ff9642eeB8f5E037c775b755" parentTag={buildClassName("SecondaryNavDa68a611Ff9642eeB8f5E037c775b755", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="Footer5bfdda4904704f7190197918edc3fc45" {...props} />}
    </div>
  );
}

// id: "edbfdf63-e623-4dc6-90dd-62f8297be560"
// title: "Polytopia 2024 Workshops"
// type: :html
// key: "polytopia-2024-workshops"
// parent_id: nil
export function Polytopia2024WorkshopsEdbfdf63E6234dc690dd62f8297be560(props: any) {
  const PageContent = AppStore.presets["PageContent"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Polytopia2024WorkshopsEdbfdf63E6234dc690dd62f8297be560 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header9c5e57655a9c456382fd0f0ba437c558" title="Polytopia 2024 Workshops" {...props} />}
      <main>
        {props["body"] || <PageContent className="BodyEd89e701Bdfe45f0959aEfc1d7ec1813" parentTag={buildClassName("BodyEd89e701Bdfe45f0959aEfc1d7ec1813", parentTag)} {...props} />}
      </main>
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterC60f49cdC5df45518fe30892abefcafe" {...props} />}
    </div>
  );
}

// id: "ea8ba891-7777-43ea-bc43-c7d7478c79c7"
// title: ""
// type: :text
// key: "title"
// parent_id: "ec54e3bd-82d7-45ba-a61c-30c9fca8de0f"
export const TitleEa8ba891777743eaBc43C7d7478c79c7 = "Secondary Marketing Title 1";

// id: "c60f49cd-c5df-4551-8fe3-0892abefcafe"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "edbfdf63-e623-4dc6-90dd-62f8297be560"
export function FooterC60f49cdC5df45518fe30892abefcafe(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterC60f49cdC5df45518fe30892abefcafe" {...props} />
  );
}

// id: "f1eff2df-82c4-47ef-bea7-5c3ff8207f01"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "0c70ac5c-8aec-48e9-b3d7-1232dd29d12d"
export function ButtonF1eff2df82c447efBea75c3ff8207f01(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button className="ButtonF1eff2df82c447efBea75c3ff8207f01" parentTag={buildClassName("ButtonF1eff2df82c447efBea75c3ff8207f01", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "99c10a86-9cad-496e-bb0d-2569310c928a"
// title: "Header"
// type: :reference
// key: "header"
// parent_id: "a4d3b278-87e2-4828-992d-024ae228f018"
export function Header99c10a869cad496eBb0d2569310c928a(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header99c10a869cad496eBb0d2569310c928a" title="Polytopia 2024" {...props} />
  );
}

// id: "b6c220b8-fb09-4a9e-b207-cc4d6503fb62"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "d00b4c71-6269-47cf-82e4-88caded3625a"
export const ImageUrlB6c220b8Fb094a9eB207Cc4d6503fb62 = "https://mp1md-pub.s3.us-west-2.amazonaws.com/spp-logo.svg";

// id: "d0b5b1fc-d711-4af7-bab6-8ae47a893645"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesD0b5b1fcD7114af7Bab68ae47a893645(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesD0b5b1fcD7114af7Bab68ae47a893645`}>
      {props.children}
    </div>
  );
}

// id: "a49ffc82-e450-4bee-8a81-0f25d89201b1"
// title: ""
// type: :text
// key: "title"
// parent_id: "38ba37dd-c2b7-4990-859a-127ad9b145a4"
export const TitleA49ffc82E4504bee8a810f25d89201b1 = null;

// id: "8ce3bed6-2d66-4c33-bacc-ff1217dde281"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "ec54e3bd-82d7-45ba-a61c-30c9fca8de0f"
export function HeaderTop8ce3bed62d664c33BaccFf1217dde281(props: any) {
  return (
    <DefaultHeaderTop69a6404f9361441d89c8Dc07071d5e63 parentTag="HeaderTop8ce3bed62d664c33BaccFf1217dde281" {...props} />
  );
}

// id: "83dfe8f0-8641-4086-a554-c539f06e60bb"
// title: ""
// type: :html
// key: "logo"
// parent_id: "71b91254-e22f-4e37-bd01-ac1376765f0f"
export function Logo83dfe8f086414086A554C539f06e60bb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/spp/images/spp-logo-white.svg" alt="SPP logo" className={`${parentTag || ""} Logo83dfe8f086414086A554C539f06e60bb logo`} />
  );
}

// id: "84d1caff-d6c5-4b35-b4e1-ebaadff8ecaa"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "b6f5dac9-72a5-4f94-8bd3-a4b40f5fbe8b"
export const PostSlug84d1caffD6c54b35B4e1Ebaadff8ecaa = "home-page-posts2";

// id: "6fe39e30-90bf-4d38-953a-081d24e8b7d4"
// title: ""
// type: :text
// key: "title"
// parent_id: "542bb484-09b0-485d-8e04-369203dcb5bf"
export const Title6fe39e3090bf4d38953a081d24e8b7d4 = "Secondary Marketing Title 2";

// id: "9efac7df-c7aa-472f-b1f4-acb13fdf5b84"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title9efac7dfC7aa472fB1f4Acb13fdf5b84 = "Leveling";

// id: "af0bc211-026a-4cdd-b4a1-0976ad128dc6"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsAf0bc211026a4cddB4a10976ad128dc6(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards className="MarketingCardsAf0bc211026a4cddB4a10976ad128dc6" parentTag={buildClassName("MarketingCardsAf0bc211026a4cddB4a10976ad128dc6", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-posts2" {...props} />
  );
}

// id: "bd5ab607-dadd-4511-8ef7-ce869816c60f"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "71b91254-e22f-4e37-bd01-ac1376765f0f"
export function LinksBd5ab607Dadd45118ef7Ce869816c60f(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation className="LinksBd5ab607Dadd45118ef7Ce869816c60f" parentTag={buildClassName("LinksBd5ab607Dadd45118ef7Ce869816c60f", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "da68a611-ff96-42ee-b8f5-e037c775b755"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "72450035-244a-4d62-9c9f-626d6446fded"
export function SecondaryNavDa68a611Ff9642eeB8f5E037c775b755(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation className="SecondaryNavDa68a611Ff9642eeB8f5E037c775b755" parentTag={buildClassName("SecondaryNavDa68a611Ff9642eeB8f5E037c775b755", parentTag)} {...props} />
  );
}

// id: "a789830e-447b-4086-976f-cb3d3454d32e"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "7403000c-468f-4c77-a132-2b994e91c64b"
export function LinkedLogoA789830e447b4086976fCb3d3454d32e(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link className="LinkedLogoA789830e447b4086976fCb3d3454d32e" parentTag={buildClassName("LinkedLogoA789830e447b4086976fCb3d3454d32e", parentTag)} label={<DefaultLogoD00b4c71626947cf82e488caded3625a parentTag="Label28a5b7b4A3b549ab84eaA77292939964" alt="logo" />} {...props} />
  );
}

// id: "e95b8c7d-b49c-4c29-ac3c-e5159e2e9e29"
// title: ""
// type: :text
// key: "url"
// parent_id: "17de7fb2-5703-41d6-9ca5-f61c9613aa75"
export const UrlE95b8c7dB49c4c29Ac3cE5159e2e9e29 = "/";

// id: "79f07419-6c03-4df4-bd2e-65af6aa58058"
// title: ""
// type: :text
// key: "title"
// parent_id: "dada0879-3bc1-4cd1-b01c-7dfd0a3ef279"
export const Title79f074196c034df4Bd2e65af6aa58058 = "Polytopia 2025";

// id: "ec54e3bd-82d7-45ba-a61c-30c9fca8de0f"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop69a6404f9361441d89c8Dc07071d5e63 parentTag="HeaderTop8ce3bed62d664c33BaccFf1217dde281" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero3732986a257d477680f87b18ee604e30 parentTag="Hero542bb48409b0485d8e04369203dcb5bf" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "77219fcd-5ef5-4f91-a1a5-6c488f83b38f"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer77219fcd5ef54f91A1a56c488f83b38f(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="Footer77219fcd5ef54f91A1a56c488f83b38f" {...props} />
  );
}

// id: "8c5caf07-a785-488b-aa07-85849d94dc3d"
// title: ""
// type: :reference
// key: "body"
// parent_id: "0d142b59-382d-480d-8e3a-c17e76089664"
export function Body8c5caf07A785488bAa0785849d94dc3d(props: any) {
  return (
    <MarketingCardsAf0bc211026a4cddB4a10976ad128dc6 parentTag="Body8c5caf07A785488bAa0785849d94dc3d" postSlug="polytopia-2025" {...props} />
  );
}

// id: "1ec8c0ee-d264-4b10-8aeb-6657e822d74e"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "015fd256-c465-42d1-933c-9cfa4760a9d1"
export function HeaderTop1ec8c0eeD2644b108aeb6657e822d74e(props: any) {
  return (
    <DefaultHeaderTop69a6404f9361441d89c8Dc07071d5e63 parentTag="HeaderTop1ec8c0eeD2644b108aeb6657e822d74e" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "b9cea281-0a46-4f23-8749-ff8215942969"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "d54dc73e-1286-411d-bd7f-17d177c4c87c"
export function FooterB9cea2810a464f238749Ff8215942969(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterB9cea2810a464f238749Ff8215942969" {...props} />
  );
}

// id: "debb517d-7df7-4386-b908-7596949003af"
// title: ""
// type: :text
// key: "title"
// parent_id: "8caa48d2-4ba0-40b2-8fd1-61c1a2b50cc1"
export const TitleDebb517d7df74386B9087596949003af = "Join Us";

// id: "3d3a20f2-5587-499c-8e76-84f0e506daca"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage3d3a20f25587499c8e7684f0e506daca(props: any) {
  return (
    <SecondaryPage70e782399a2143f5978f6f108031c2d8 parentTag="ResourcesPage3d3a20f25587499c8e7684f0e506daca" header={<SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header04fd2815268d4920A5d134fe13f53fb3" title="Resources" />} {...props} />
  );
}

// id: "7dbfd219-0a83-4f4b-b336-f39ec03b576a"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader7dbfd2190a834f4bB336F39ec03b576a(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader7dbfd2190a834f4bB336F39ec03b576a site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop69a6404f9361441d89c8Dc07071d5e63 parentTag="HeaderTop4467d57fE49246beA8913e7d9bfc28ec" {...props} />}
      </div>
      {props["hero"] || <Hero className="HeroF9be20f10db94c3fBac5060369730679" parentTag={buildClassName("HeroF9be20f10db94c3fBac5060369730679", parentTag)} postSlug="home-hero" {...props} />}
    </div>
  );
}

// id: "862860bf-b096-4a7d-b854-9175b26b8c37"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "bb943482-580c-43a8-b2fd-d7d45fbf6fc7"
export function Footer862860bfB0964a7dB8549175b26b8c37(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="Footer862860bfB0964a7dB8549175b26b8c37" {...props} />
  );
}

// id: "befc69ae-8d20-4d3b-af00-a1dbde7e9353"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a8e9b68a-7af0-4c82-a184-0b0ee7948196"
export function FooterBefc69ae8d204d3bAf00A1dbde7e9353(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterBefc69ae8d204d3bAf00A1dbde7e9353" {...props} />
  );
}

// id: "c2a2c5e3-1e04-468c-a208-ea9ea5b2abc2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "72450035-244a-4d62-9c9f-626d6446fded"
export function HeaderC2a2c5e31e04468cA208Ea9ea5b2abc2(props: any) {
  return (
    <MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1 parentTag="HeaderC2a2c5e31e04468cA208Ea9ea5b2abc2" {...props} />
  );
}

// id: "21663a1b-134e-403a-9044-7aef77b46304"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "bd5ab607-dadd-4511-8ef7-ce869816c60f"
export const NavMenuSlug21663a1b134e403a90447aef77b46304 = "footer-nav";

// id: "d54dc73e-1286-411d-bd7f-17d177c4c87c"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageD54dc73e1286411dBd7f17d177c4c87c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageD54dc73e1286411dBd7f17d177c4c87c gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header5fb71b8c518241c4803863d6e1dd4492" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterB9cea2810a464f238749Ff8215942969" {...props} />}
    </div>
  );
}

// id: "d8724453-6fe1-4a5a-9c92-a8f69248bad5"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "9100b6d7-8322-4894-9a12-936c9b39d6f6"
export function FooterD87244536fe14a5a9c92A8f69248bad5(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterD87244536fe14a5a9c92A8f69248bad5" {...props} />
  );
}

// id: "874fd064-a191-4d90-9031-5f61f9ac4e99"
// title: ""
// type: :reference
// key: "body"
// parent_id: "8088edd1-e490-4e36-b1b1-5ffd140601be"
export function Body874fd064A1914d9090315f61f9ac4e99(props: any) {
  return (
    <MarketingBodyDb08870a1bc04537Abe02c8272a5529a parentTag="Body874fd064A1914d9090315f61f9ac4e99" {...props} />
  );
}

// id: "20aa384b-6f23-4e67-9420-bf1aae63ed3a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a8e9b68a-7af0-4c82-a184-0b0ee7948196"
export function Header20aa384b6f234e679420Bf1aae63ed3a(props: any) {
  return (
    <MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1 parentTag="Header20aa384b6f234e679420Bf1aae63ed3a" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "610b3d98-d9d6-43c6-af8d-e6a755cdff1c"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "8c5caf07-a785-488b-aa07-85849d94dc3d"
export const PostSlug610b3d98D9d643c6Af8dE6a755cdff1c = "polytopia-2025";

// id: "bbd1b068-ab3a-4a72-b4ef-fc03158530cb"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "55f86eba-1574-4564-b341-ec653e9f847c"
export const PostSlugBbd1b068Ab3a4a72B4efFc03158530cb = "about-us-posts";

// id: "3732986a-257d-4776-80f8-7b18ee604e30"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero3732986a257d477680f87b18ee604e30(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero3732986a257d477680f87b18ee604e30 hero`}>
      <div className="title">
        {props["title"] || Title83f172d7D27845a5Afb0F884dff24735}
      </div>
    </div>
  );
}

// id: "f15035d4-50ff-4b78-a7bf-95c842dd74eb"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function LabelF15035d450ff4b78A7bf95c842dd74eb(props: any) {
  return (
    <DefaultLogoD00b4c71626947cf82e488caded3625a parentTag="LabelF15035d450ff4b78A7bf95c842dd74eb" {...props} />
  );
}

// id: "936f75bd-a526-4448-bea7-128328231535"
// title: ""
// type: :reference
// key: "header"
// parent_id: "8088edd1-e490-4e36-b1b1-5ffd140601be"
export function Header936f75bdA5264448Bea7128328231535(props: any) {
  return (
    <HomeHeader7dbfd2190a834f4bB336F39ec03b576a parentTag="Header936f75bdA5264448Bea7128328231535" {...props} />
  );
}

// id: "89b2ad6a-8598-44e8-8aec-1c02de6854a3"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "af0bc211-026a-4cdd-b4a1-0976ad128dc6"
export const ButtonClass89b2ad6a859844e88aec1c02de6854a3 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "7602b7a0-8ce6-4bf9-ac47-9dd54c888d80"
// title: ""
// type: :text
// key: "href"
// parent_id: "f1eff2df-82c4-47ef-bea7-5c3ff8207f01"
export const Href7602b7a08ce64bf9Ac479dd54c888d80 = "/join";

// id: "80386313-c2f1-4ead-9bd5-33009d247fec"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage80386313C2f14ead9bd533009d247fec(props: any) {
  return (
    <SecondaryPage70e782399a2143f5978f6f108031c2d8 parentTag="JoinUsPage80386313C2f14ead9bd533009d247fec" header={<SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="HeaderF2fc67976d9c4743B00916c37e3f007c" title="Join Us" />} {...props} />
  );
}

// id: "04fd2815-268d-4920-a5d1-34fe13f53fb3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "3d3a20f2-5587-499c-8e76-84f0e506daca"
export function Header04fd2815268d4920A5d134fe13f53fb3(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header04fd2815268d4920A5d134fe13f53fb3" title="Resources" {...props} />
  );
}

// id: "1f13819c-be5a-4682-a520-b0da972f8cec"
// title: ""
// type: :reference
// key: "header"
// parent_id: "70e78239-9a21-43f5-978f-6f108031c2d8"
export function Header1f13819cBe5a4682A520B0da972f8cec(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header1f13819cBe5a4682A520B0da972f8cec" title="Secondary Page" {...props} />
  );
}

// id: "f2fc6797-6d9c-4743-b009-16c37e3f007c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "80386313-c2f1-4ead-9bd5-33009d247fec"
export function HeaderF2fc67976d9c4743B00916c37e3f007c(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="HeaderF2fc67976d9c4743B00916c37e3f007c" title="Join Us" {...props} />
  );
}

// id: "09e04a71-3de5-4306-b082-fd3bd298092a"
// title: ""
// type: :text
// key: "label"
// parent_id: "f1eff2df-82c4-47ef-bea7-5c3ff8207f01"
export const Label09e04a713de54306B082Fd3bd298092a = "Join Us";

// id: "33f16a3e-57ae-4e40-bcb2-4a3f19b422dc"
// title: ""
// type: :text
// key: "title"
// parent_id: "0fae61c9-9c96-4b7f-bb19-d9a14ebe41f2"
export const Title33f16a3e57ae4e40Bcb24a3f19b422dc = "Leveling";

// id: "71b91254-e22f-4e37-bd01-ac1376765f0f"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f footer`}>
      {props["social-links"] || <SocialLinks1a1f0e1877e14830A8bd31938c55fe3b />}
      {props["links"] || <Navigation className="LinksBd5ab607Dadd45118ef7Ce869816c60f" parentTag={buildClassName("LinksBd5ab607Dadd45118ef7Ce869816c60f", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo83dfe8f086414086A554C539f06e60bb />}
      </div>
      {props["copyright"] || <GroupCopyright className="Copyright8a54bf4fEd674b8dA9e2526dfb479e4f" parentTag={buildClassName("Copyright8a54bf4fEd674b8dA9e2526dfb479e4f", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag className="Groupflow8c1cff10Ddee4f87Bcb7329204cd4be1" parentTag={buildClassName("Groupflow8c1cff10Ddee4f87Bcb7329204cd4be1", parentTag)} {...props} />}
    </div>
  );
}

// id: "d00b4c71-6269-47cf-82e4-88caded3625a"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoD00b4c71626947cf82e488caded3625a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.us-west-2.amazonaws.com/spp-logo.svg" className={`${parentTag || ""} DefaultLogoD00b4c71626947cf82e488caded3625a logo`} alt="logo" />
  );
}

// id: "97d9fb02-d5b7-4c87-bf60-5112d426e2a6"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "7403000c-468f-4c77-a132-2b994e91c64b"
export const BackgroundImage97d9fb02D5b74c87Bf605112d426e2a6 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "8caa48d2-4ba0-40b2-8fd1-61c1a2b50cc1"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4b19b3e4-88ce-4199-9bb6-fa537dac159e"
export function Header8caa48d24ba040b28fd161c1a2b50cc1(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="Header8caa48d24ba040b28fd161c1a2b50cc1" title="Join Us" {...props} />
  );
}

// id: "17de7fb2-5703-41d6-9ca5-f61c9613aa75"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "69a6404f-9361-441d-89c8-dc07071d5e63"
export function LinkedLogo17de7fb2570341d69ca5F61c9613aa75(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link className="LinkedLogo17de7fb2570341d69ca5F61c9613aa75" parentTag={buildClassName("LinkedLogo17de7fb2570341d69ca5F61c9613aa75", parentTag)} className="linked-logo" url="/" label={<DefaultLogoD00b4c71626947cf82e488caded3625a parentTag="Label877957293d6c43949d0cB7c1c1e31519" alt="logo" />} {...props} />
  );
}

// id: "fa11392f-d43b-48a7-a0e0-5df3b96c6530"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "20aa384b-6f23-4e67-9420-bf1aae63ed3a"
export const NavMenuSlugFa11392fD43b48a7A0e05df3b96c6530 = "members-primary-nav";

// id: "e10f71ee-b2c9-42a1-9bea-43ac513c7ab9"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "121720d2-2d35-485b-807a-de46f8252272"
export const PostSlugE10f71eeB2c942a19bea43ac513c7ab9 = "polytopia-2024";

// id: "0c70ac5c-8aec-48e9-b3d7-1232dd29d12d"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero0c70ac5c8aec48e9B3d71232dd29d12d(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero0c70ac5c8aec48e9B3d71232dd29d12d hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy sexuality through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button className="ButtonF1eff2df82c447efBea75c3ff8207f01" parentTag={buildClassName("ButtonF1eff2df82c447efBea75c3ff8207f01", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "2d9007ac-81e5-4f04-a714-d956bb8d9698"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href2d9007ac81e54f04A714D956bb8d9698 = "/";

// id: "bfd6523c-0ead-43f5-b864-76d5af4dc20c"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "69a6404f-9361-441d-89c8-dc07071d5e63"
export function NavigationBfd6523c0ead43f5B86476d5af4dc20c(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation className="NavigationBfd6523c0ead43f5B86476d5af4dc20c" parentTag={buildClassName("NavigationBfd6523c0ead43f5B86476d5af4dc20c", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "cebebb34-de67-4889-9ced-56c67d8c8c0f"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "70e78239-9a21-43f5-978f-6f108031c2d8"
export function FooterCebebb34De6748899ced56c67d8c8c0f(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="FooterCebebb34De6748899ced56c67d8c8c0f" {...props} />
  );
}

// id: "7a52bc22-1941-4875-b5c0-34149b054aed"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "8088edd1-e490-4e36-b1b1-5ffd140601be"
export function Quote7a52bc2219414875B5c034149b054aed(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote className="Quote7a52bc2219414875B5c034149b054aed" parentTag={buildClassName("Quote7a52bc2219414875B5c034149b054aed", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "f6d81056-a15a-4dc1-932f-bbf51fec2c65"
// title: ""
// type: :text
// key: "title"
// parent_id: "04fd2815-268d-4920-a5d1-34fe13f53fb3"
export const TitleF6d81056A15a4dc1932fBbf51fec2c65 = "Resources";

// id: "9b6f0816-610d-43e0-844e-9209e0794f63"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "7a52bc22-1941-4875-b5c0-34149b054aed"
export const ContentSlug9b6f0816610d43e0844e9209e0794f63 = "home-page-quote";

// id: "69a6404f-9361-441d-89c8-dc07071d5e63"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop69a6404f9361441d89c8Dc07071d5e63(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop69a6404f9361441d89c8Dc07071d5e63 header-top`}>
      {props["linked-logo"] || <Link className="LinkedLogo17de7fb2570341d69ca5F61c9613aa75" parentTag={buildClassName("LinkedLogo17de7fb2570341d69ca5F61c9613aa75", parentTag)} className="linked-logo" url="/" label={<DefaultLogoD00b4c71626947cf82e488caded3625a parentTag="Label877957293d6c43949d0cB7c1c1e31519" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation className="NavigationBfd6523c0ead43f5B86476d5af4dc20c" parentTag={buildClassName("NavigationBfd6523c0ead43f5B86476d5af4dc20c", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "7dce09fb-f4e6-4faa-81c9-a0f57e6de6f4"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages7dce09fbF4e64faa81c9A0f57e6de6f4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages7dce09fbF4e64faa81c9A0f57e6de6f4 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1 parentTag="HeaderF8f10c653dfb4c1e8ad22056ebdccbbc" {...props} />}
      {props.children}
    </div>
  );
}

// id: "542bb484-09b0-485d-8e04-369203dcb5bf"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "ec54e3bd-82d7-45ba-a61c-30c9fca8de0f"
export function Hero542bb48409b0485d8e04369203dcb5bf(props: any) {
  return (
    <SecondaryMarketingHero3732986a257d477680f87b18ee604e30 parentTag="Hero542bb48409b0485d8e04369203dcb5bf" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "5bfdda49-0470-4f71-9019-7918edc3fc45"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "72450035-244a-4d62-9c9f-626d6446fded"
export function Footer5bfdda4904704f7190197918edc3fc45(props: any) {
  return (
    <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="Footer5bfdda4904704f7190197918edc3fc45" {...props} />
  );
}

// id: "4467d57f-e492-46be-a891-3e7d9bfc28ec"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "7dbfd219-0a83-4f4b-b336-f39ec03b576a"
export function HeaderTop4467d57fE49246beA8913e7d9bfc28ec(props: any) {
  return (
    <DefaultHeaderTop69a6404f9361441d89c8Dc07071d5e63 parentTag="HeaderTop4467d57fE49246beA8913e7d9bfc28ec" {...props} />
  );
}

// id: "d9f874df-bdd8-4b80-bc60-615e692a0a22"
// title: ""
// type: :text
// key: "class"
// parent_id: "bfd6523c-0ead-43f5-b864-76d5af4dc20c"
export const ClassD9f874dfBdd84b80Bc60615e692a0a22 = "navigation";

// id: "7403000c-468f-4c77-a132-2b994e91c64b"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader7403000c468f4c77A1322b994e91c64b(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader7403000c468f4c77A1322b994e91c64b site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link className="LinkedLogoA789830e447b4086976fCb3d3454d32e" parentTag={buildClassName("LinkedLogoA789830e447b4086976fCb3d3454d32e", parentTag)} label={<DefaultLogoD00b4c71626947cf82e488caded3625a parentTag="Label28a5b7b4A3b549ab84eaA77292939964" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation className="NavigationEc64b73981cf458b85dbCbb5a47e065c" parentTag={buildClassName("NavigationEc64b73981cf458b85dbCbb5a47e065c", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "ec64b739-81cf-458b-85db-cbb5a47e065c"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "7403000c-468f-4c77-a132-2b994e91c64b"
export function NavigationEc64b73981cf458b85dbCbb5a47e065c(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation className="NavigationEc64b73981cf458b85dbCbb5a47e065c" parentTag={buildClassName("NavigationEc64b73981cf458b85dbCbb5a47e065c", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "dada0879-3bc1-4cd1-b01c-7dfd0a3ef279"
// title: "Header Clone SiJc8owDIOQgqYh"
// type: :reference
// key: "header"
// parent_id: "0d142b59-382d-480d-8e3a-c17e76089664"
export function HeaderDada08793bc14cd1B01c7dfd0a3ef279(props: any) {
  return (
    <SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f parentTag="HeaderDada08793bc14cd1B01c7dfd0a3ef279" title="Polytopia 2025" {...props} />
  );
}

// id: "121720d2-2d35-485b-807a-de46f8252272"
// title: ""
// type: :reference
// key: "body"
// parent_id: "a4d3b278-87e2-4828-992d-024ae228f018"
export function Body121720d22d35485b807aDe46f8252272(props: any) {
  return (
    <MarketingCardsAf0bc211026a4cddB4a10976ad128dc6 parentTag="Body121720d22d35485b807aDe46f8252272" postSlug="polytopia-2024" {...props} />
  );
}

// id: "2a7572e7-6682-47ef-9361-570bccd52c80"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "f9be20f1-0db9-4c3f-bac5-060369730679"
export const PostSlug2a7572e7668247ef9361570bccd52c80 = "home-hero";

// id: "87795729-3d6c-4394-9d0c-b7c1c1e31519"
// title: ""
// type: :reference
// key: "label"
// parent_id: "17de7fb2-5703-41d6-9ca5-f61c9613aa75"
export function Label877957293d6c43949d0cB7c1c1e31519(props: any) {
  return (
    <DefaultLogoD00b4c71626947cf82e488caded3625a parentTag="Label877957293d6c43949d0cB7c1c1e31519" {...props} />
  );
}

// id: "8088edd1-e490-4e36-b1b1-5ffd140601be"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage8088edd1E4904e36B1b15ffd140601be(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage8088edd1E4904e36B1b15ffd140601be page gf-page-layout page gf-page-layout`}>
      {props["header"] || <HomeHeader7dbfd2190a834f4bB336F39ec03b576a parentTag="Header936f75bdA5264448Bea7128328231535" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp className="WelcomeBack2ac2a1b0Bc8c49228739204098c8b1d6" parentTag={buildClassName("WelcomeBack2ac2a1b0Bc8c49228739204098c8b1d6", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyDb08870a1bc04537Abe02c8272a5529a parentTag="Body874fd064A1914d9090315f61f9ac4e99" {...props} />}
      {props["quote"] || <PageQuote className="Quote7a52bc2219414875B5c034149b054aed" parentTag={buildClassName("Quote7a52bc2219414875B5c034149b054aed", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f parentTag="Footer7ffde64f09cb402e951fE89a4b6abac5" {...props} />}
    </div>
  );
}

// id: "55f86eba-1574-4564-b341-ec653e9f847c"
// title: ""
// type: :reference
// key: "body"
// parent_id: "bb943482-580c-43a8-b2fd-d7d45fbf6fc7"
export function Body55f86eba15744564B341Ec653e9f847c(props: any) {
  return (
    <MarketingBodyDb08870a1bc04537Abe02c8272a5529a parentTag="Body55f86eba15744564B341Ec653e9f847c" postSlug="about-us-posts" {...props} />
  );
}

// id: "193051d6-2055-4420-bb0e-f7f17849960c"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "af0bc211-026a-4cdd-b4a1-0976ad128dc6"
export const PostSlug193051d620554420Bb0eF7f17849960c = "home-page-posts2";

// id: "b6f5dac9-72a5-4f94-8bd3-a4b40f5fbe8b"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "db08870a-1bc0-4537-abe0-2c8272a5529a"
export function MarketingCardsB6f5dac972a54f948bd3A4b40f5fbe8b(props: any) {
  return (
    <MarketingCardsAf0bc211026a4cddB4a10976ad128dc6 parentTag="MarketingCardsB6f5dac972a54f948bd3A4b40f5fbe8b" postSlug="home-page-posts2" {...props} />
  );
}

// id: "3921d3b3-b992-476d-86f6-6b12e4c10d3e"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "ec64b739-81cf-458b-85db-cbb5a47e065c"
export const NavMenuSlug3921d3b3B992476d86f66b12e4c10d3e = "members-primary-nav";

// id: "1a1f0e18-77e1-4830-a8bd-31938c55fe3b"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "71b91254-e22f-4e37-bd01-ac1376765f0f"
export function SocialLinks1a1f0e1877e14830A8bd31938c55fe3b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks1a1f0e1877e14830A8bd31938c55fe3b flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sp_portland/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px", fill: "currentColor"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/sexpositiveportland/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px", fill: "currentColor"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "e7365e8b-f7c7-4a93-aaee-25a634ddb7b8"
// title: ""
// type: :text
// key: "alt"
// parent_id: "d00b4c71-6269-47cf-82e4-88caded3625a"
export const AltE7365e8bF7c74a93Aaee25a634ddb7b8 = "logo";

// id: "8c1cff10-ddee-4f87-bcb7-329204cd4be1"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "71b91254-e22f-4e37-bd01-ac1376765f0f"
export function Groupflow8c1cff10Ddee4f87Bcb7329204cd4be1(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag className="Groupflow8c1cff10Ddee4f87Bcb7329204cd4be1" parentTag={buildClassName("Groupflow8c1cff10Ddee4f87Bcb7329204cd4be1", parentTag)} {...props} />
  );
}

// id: "28a5b7b4-a3b5-49ab-84ea-a77292939964"
// title: ""
// type: :reference
// key: "label"
// parent_id: "a789830e-447b-4086-976f-cb3d3454d32e"
export function Label28a5b7b4A3b549ab84eaA77292939964(props: any) {
  return (
    <DefaultLogoD00b4c71626947cf82e488caded3625a parentTag="Label28a5b7b4A3b549ab84eaA77292939964" {...props} />
  );
}

const Components = {
  SignupPages4b19b3e488ce41999bb6Fa537dac159e,
  WelcomeBack2ac2a1b0Bc8c49228739204098c8b1d6,
  FooterCf45f16bA1044961B653Cfd13b804f15,
  SecondaryPage70e782399a2143f5978f6f108031c2d8,
  NavMenuSlugC93640ad95694a80Be7e1be2163d3dea,
  Title83f172d7D27845a5Afb0F884dff24735,
  FooterCdc86cd2F8a84a608f932d402fdc8322,
  HeroF9be20f10db94c3fBac5060369730679,
  BackgroundImageCd857a08B72a435c88260d832644e316,
  NavMenuSlug64d01229276b4c0494bfFc84b5cc2e37,
  Title0c20e20aDa334872826a7e0981e75433,
  Title79aafcb863a04409Aba3326e382349ed,
  Title056c6c977a584ac0A415Fad1445a25ec,
  NavMenuSlugC9877057Bcf84b238f032df90c0a7abd,
  MarketingBodyDb08870a1bc04537Abe02c8272a5529a,
  AboutUsPageBb943482580c43a8B2fdD7d45fbf6fc7,
  PageQuoteD295e04d54064567A830252bcef5d7d9,
  BodyEd89e701Bdfe45f0959aEfc1d7ec1813,
  Header9c5e57655a9c456382fd0f0ba437c558,
  Class9f713410576d490cB7381791fec76e8f,
  Title2a3c03ac11824fae9cb9D5f68328aae0,
  Title97bfcb6d475647aa960f942831299efa,
  Header9cffba077b7247e1B8883703364f9676,
  MembersAreaHeader015fd256C46542d1933c9cfa4760a9d1,
  HeaderF8f10c653dfb4c1e8ad22056ebdccbbc,
  DefaultHeader38ba37ddC2b74990859a127ad9b145a4,
  MembersAreaDefaultA8e9b68a7af04c82A1840b0ee7948196,
  Polytopia2024A4d3b27887e24828992d024ae228f018,
  Polytopia20250d142b59382d480d8e3aC17e76089664,
  Copyright8a54bf4fEd674b8dA9e2526dfb479e4f,
  Header10dd4465Fc2b488c9ec66e1472cfb5ff,
  Header0fae61c99c964b7fBb19D9a14ebe41f2,
  Footer7ffde64f09cb402e951fE89a4b6abac5,
  Header5fb71b8c518241c4803863d6e1dd4492,
  Title60dd333aCbb1404fA5ffC00f06407906,
  AboutLevelingPage9100b6d7832248949a12936c9b39d6f6,
  MemberPages72450035244a4d629c9f626d6446fded,
  Polytopia2024WorkshopsEdbfdf63E6234dc690dd62f8297be560,
  TitleEa8ba891777743eaBc43C7d7478c79c7,
  FooterC60f49cdC5df45518fe30892abefcafe,
  ButtonF1eff2df82c447efBea75c3ff8207f01,
  Header99c10a869cad496eBb0d2569310c928a,
  ImageUrlB6c220b8Fb094a9eB207Cc4d6503fb62,
  AdminPagesD0b5b1fcD7114af7Bab68ae47a893645,
  TitleA49ffc82E4504bee8a810f25d89201b1,
  HeaderTop8ce3bed62d664c33BaccFf1217dde281,
  Logo83dfe8f086414086A554C539f06e60bb,
  PostSlug84d1caffD6c54b35B4e1Ebaadff8ecaa,
  Title6fe39e3090bf4d38953a081d24e8b7d4,
  Title9efac7dfC7aa472fB1f4Acb13fdf5b84,
  MarketingCardsAf0bc211026a4cddB4a10976ad128dc6,
  LinksBd5ab607Dadd45118ef7Ce869816c60f,
  SecondaryNavDa68a611Ff9642eeB8f5E037c775b755,
  LinkedLogoA789830e447b4086976fCb3d3454d32e,
  UrlE95b8c7dB49c4c29Ac3cE5159e2e9e29,
  Title79f074196c034df4Bd2e65af6aa58058,
  SecondaryMarketingHeaderEc54e3bd82d745baA61c30c9fca8de0f,
  Footer77219fcd5ef54f91A1a56c488f83b38f,
  Body8c5caf07A785488bAa0785849d94dc3d,
  HeaderTop1ec8c0eeD2644b108aeb6657e822d74e,
  FooterB9cea2810a464f238749Ff8215942969,
  TitleDebb517d7df74386B9087596949003af,
  ResourcesPage3d3a20f25587499c8e7684f0e506daca,
  HomeHeader7dbfd2190a834f4bB336F39ec03b576a,
  Footer862860bfB0964a7dB8549175b26b8c37,
  FooterBefc69ae8d204d3bAf00A1dbde7e9353,
  HeaderC2a2c5e31e04468cA208Ea9ea5b2abc2,
  NavMenuSlug21663a1b134e403a90447aef77b46304,
  PublicEventsPageD54dc73e1286411dBd7f17d177c4c87c,
  FooterD87244536fe14a5a9c92A8f69248bad5,
  Body874fd064A1914d9090315f61f9ac4e99,
  Header20aa384b6f234e679420Bf1aae63ed3a,
  PostSlug610b3d98D9d643c6Af8dE6a755cdff1c,
  PostSlugBbd1b068Ab3a4a72B4efFc03158530cb,
  SecondaryMarketingHero3732986a257d477680f87b18ee604e30,
  LabelF15035d450ff4b78A7bf95c842dd74eb,
  Header936f75bdA5264448Bea7128328231535,
  ButtonClass89b2ad6a859844e88aec1c02de6854a3,
  Href7602b7a08ce64bf9Ac479dd54c888d80,
  JoinUsPage80386313C2f14ead9bd533009d247fec,
  Header04fd2815268d4920A5d134fe13f53fb3,
  Header1f13819cBe5a4682A520B0da972f8cec,
  HeaderF2fc67976d9c4743B00916c37e3f007c,
  Label09e04a713de54306B082Fd3bd298092a,
  Title33f16a3e57ae4e40Bcb24a3f19b422dc,
  DefaultFooter71b91254E22f4e37Bd01Ac1376765f0f,
  DefaultLogoD00b4c71626947cf82e488caded3625a,
  BackgroundImage97d9fb02D5b74c87Bf605112d426e2a6,
  Header8caa48d24ba040b28fd161c1a2b50cc1,
  LinkedLogo17de7fb2570341d69ca5F61c9613aa75,
  NavMenuSlugFa11392fD43b48a7A0e05df3b96c6530,
  PostSlugE10f71eeB2c942a19bea43ac513c7ab9,
  HomePageHero0c70ac5c8aec48e9B3d71232dd29d12d,
  Href2d9007ac81e54f04A714D956bb8d9698,
  NavigationBfd6523c0ead43f5B86476d5af4dc20c,
  FooterCebebb34De6748899ced56c67d8c8c0f,
  Quote7a52bc2219414875B5c034149b054aed,
  TitleF6d81056A15a4dc1932fBbf51fec2c65,
  ContentSlug9b6f0816610d43e0844e9209e0794f63,
  DefaultHeaderTop69a6404f9361441d89c8Dc07071d5e63,
  MessagePages7dce09fbF4e64faa81c9A0f57e6de6f4,
  Hero542bb48409b0485d8e04369203dcb5bf,
  Footer5bfdda4904704f7190197918edc3fc45,
  HeaderTop4467d57fE49246beA8913e7d9bfc28ec,
  ClassD9f874dfBdd84b80Bc60615e692a0a22,
  ShortHeader7403000c468f4c77A1322b994e91c64b,
  NavigationEc64b73981cf458b85dbCbb5a47e065c,
  HeaderDada08793bc14cd1B01c7dfd0a3ef279,
  Body121720d22d35485b807aDe46f8252272,
  PostSlug2a7572e7668247ef9361570bccd52c80,
  Label877957293d6c43949d0cB7c1c1e31519,
  HomePage8088edd1E4904e36B1b15ffd140601be,
  Body55f86eba15744564B341Ec653e9f847c,
  PostSlug193051d620554420Bb0eF7f17849960c,
  MarketingCardsB6f5dac972a54f948bd3A4b40f5fbe8b,
  NavMenuSlug3921d3b3B992476d86f66b12e4c10d3e,
  SocialLinks1a1f0e1877e14830A8bd31938c55fe3b,
  AltE7365e8bF7c74a93Aaee25a634ddb7b8,
  Groupflow8c1cff10Ddee4f87Bcb7329204cd4be1,
  Label28a5b7b4A3b549ab84eaA77292939964
}

export default Components;